<script setup>
import {onMounted, ref, computed} from "vue";
import { Toast as bootstrapToast } from "bootstrap";

const props = defineProps(['header','id','icon', 'type']);
const emit = defineEmits(['deleteMessage']);
const el = ref();

const messageType = computed(()=>{
  return  'text-bg-'+props.type;
});

onMounted(()=>{
  let toast =  new bootstrapToast(el.value);
  toast.show();
  el.value.addEventListener('hidden.bs.toast', () => {
    emit('deleteMessage',props.id);
  });
});

</script>
<template>
  <div ref="el" :id="id" class="toast border-0" :class="messageType" role="alert" aria-live="assertive" aria-atomic="true" >
    <div class="toast-header">
      <i class="icon" :class="icon" v-if="icon"></i>
      <strong class="me-auto">{{header}}</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      <slot></slot>
    </div>
  </div>
</template>