<script setup>
import {ref, onMounted, inject} from "vue";
import {Modal as bootstrapModal } from "bootstrap";
import SecuredBadge from "../SecuredBadge.vue";

const props = defineProps(['title','share','size','footerClose']);
const emit = defineEmits(['modalCreated']);
const shareLink = inject('shareLink');
const modalId = ref('modal-' + Math.floor(Math.random() * Date.now()).toString(16));

const shareUrl = function () {
  let url = new URL(props.share,window.location.origin);
  shareLink(url.toString());
};

onMounted(()=>{
  let modal_element = document.getElementById(modalId.value);
  let modal = new bootstrapModal(modal_element,{'backdrop':'static'});
  emit('modalCreated',modal);
});

</script>
<template>
  <div class="modal" :id="modalId" tabindex="-1">
    <div class="modal-dialog" :class="size">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" v-if="footerClose">close</button>
          <a href="#" @click.prevent="shareUrl" class="btn btn-outline-light btn-share" v-if="share">
            <i class="icon share">
              <SecuredBadge :class="['position-relative',{'secured': secured}]" v-if="secured"/>
            </i>
          </a>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>