<script setup>
import {ref} from "vue";
import Toast from "./bootstrap/Toast.vue";

const generateId = function () {
  return 'toast-' + Math.floor(Math.random() * Date.now()).toString(16);
};

const messages = ref([]);

const addMessage = (header, body, type = 'success', icon = false) => {
  let toast_id = generateId();
  messages.value.push({
    id: toast_id,
    icon: icon,
    type: type,
    header: header,
    body: body
  });
};

defineExpose({addMessage});

const deleteMessage = function (id) {
  messages.value.forEach((message,index,object)=>{
    if(id === message.id){
      object.splice(index,1);
    }
  });
};


</script>
<template>
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <toast v-for="message in messages" @delete-message="deleteMessage" :header="message.header" :type="message.type" :id="message.id" :icon="message.icon"> {{message.body}}</toast>
  </div>
</template>