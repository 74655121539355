<script setup>
import {ref, onMounted, onUpdated, watch, inject, reactive, provide, computed} from "vue";
import Modal from "./bootstrap/Modal.vue";

const showMessage = inject('showMessage');
const router = inject('router');

const loginModal = ref(null);
const pingRunner = ref();
const active_callback = ref();

const userStatus = {
  0: 'logged_out',
  1: 'logged_in',
  2: 'pending'
};

const user = ref({
  status: userStatus["0"],
  data: {},
  tokens:{
    access_token:false,
    refresh_token:false
  }
});

const getBearer = function () {
  return `Bearer ${user.value.tokens.access_token}`;
};

const loginForm = ref({
  username: '',
  password: ''
});

const userIndicator = computed(()=>({
  'private': user.value.status === userStatus["0"],
  'private-loggedin': user.value.status === userStatus["1"],
  'pending': user.value.status === userStatus["2"],
}));

const userLoggedIn = computed(()=>{
  return user.value.status === userStatus["1"];
});

const loadingState = computed(()=>{
  return user.value.status === userStatus["2"];
});

const modalCreated = function (modal) {
  loginModal.value = modal;
  loginModal.value._element.addEventListener('hidden.bs.modal', event => {

  })
};

const openLoginModal = function (callback = false | '') {
  active_callback.value = callback;
  loginModal.value.show()
};

async function getUserData(){
  const user_data = await fetch('/current_user', {
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getBearer()
    },
  });
  if(!user_data.ok){
    throw new Error(`${user_data.statusText} (${user_data.status})`);
  }

  return await user_data.json()
}

async function getUserStatus(){
  const user_status = await fetch('/user_status', {
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
  if(!user_status.ok){
    throw new Error(`${user_status.statusText} (${user_status.status})`);
  }

  return await user_status.json()
}

async function doPing(){
  const token_request = await fetch('/ping', {
    method: "POST",
    headers: {
      'Accept': 'application/json'
    }
  });

  if(!token_request.ok){
    throw new Error(`${token_request.statusText} (${token_request.status})`);
  }

  return await token_request.json();
}

async function doLogin(){
  let login_data = JSON.stringify(loginForm.value);
  const dataResponse = await fetch('/login',{
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: login_data
  });

  if(!dataResponse.ok){
    throw new Error(`${dataResponse.statusText} (${dataResponse.status})`);
  }

  return await dataResponse.json();
}

const doCallback = () => {
  if(active_callback.value){
    const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/gm;
    if (urlPattern.test(active_callback.value) === true){
      window.location.replace(active_callback.value);
    }else{
      router.replace(active_callback.value);
    }
  }
};

const login = () => {
  user.value.status = userStatus["2"];
  doLogin().then(tokens => {
      user.value.tokens = tokens;
      user.value.status = userStatus["1"];
      showMessage('Login','Successfully logged in','success');
      startPing();
      doCallback();
      loginModal.value.hide();
    }).catch(error => {
      user.value.tokens.access_token = false;
      user.value.status = userStatus["0"];
      showMessage('Login','Login failed','danger');
      console.error(error.message);
    }).finally(() => {
      // loadingState.value = false;
    });
};

const logout = () => {
  user.value.status = userStatus["2"];
  let refresh_data = {refresh_token: user.value.tokens.refresh_token};
  let logout_request = fetch('/logout', {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(refresh_data)
  }).then(() => {
    user.value.tokens.access_token = false;
    user.value.tokens.refresh_token = false;
    user.value.status = userStatus["0"];
    showMessage('Logout','Successfully logged out','success');
  }).catch(error => {
    showMessage('Logout','Something went wrong','danger');
  });
};

const register = () => {
  window.open('/register', '_blank');
};

const ping = () => {
  doPing().then(tokens => {
    user.value.tokens.access_token = tokens.access_token;
    user.value.tokens.refresh_token = tokens.refresh_token;
    user.value.status = userStatus["1"];
    initUserData();
  }).catch(error => {
    console.error('ping error',error.status)
    user.value.tokens.access_token = false;
    user.value.status = userStatus["0"];
    stopPing();
  });
};

const initUser = () => {
  user.value.status = userStatus["2"];
  if(USER_INIT.tokens.hasOwnProperty('access_token')){
    user.value.tokens = USER_INIT.tokens;
    user.value.status = userStatus["1"];
    startPing();
  }else{
    user.value.tokens.access_token = false;
    user.value.status = userStatus["0"];
  }
};

const initUserData = () => {
  if(!user.value.data.hasOwnProperty('azp')){
    getUserData().then(user_data => {
      user.value.data = user_data;
    })
  }
};

const startPing = () => {
  ping();
  pingRunner.value = setInterval(ping, 50000 );
};

const stopPing = () => {
  clearInterval(pingRunner.value);
};

defineExpose({getBearer,userLoggedIn,openLoginModal});

onMounted(()=>{
  initUser();
});

</script>

<template>
  <div class="row topbar position-fixed start-0 w-100 p-2 top-0 z-3 g-0">
    <div class="col">
      <button class="btn btn-auth" @click.prevent="openLoginModal" v-if="userStatus['0'] === user.status">
        <i class="icon" :class="userIndicator"></i>
      </button>

      <div class="dropdown" v-if="userStatus['1'] === user.status">
        <button class="btn btn-auth dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="icon" :class="userIndicator"></i> <span v-if="user.data.name">{{user.data.name}}</span>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#" @click.prevent="logout"><i class="icon logout"></i> Logout</a></li>
        </ul>
      </div>
    </div>
  </div>

  <teleport to="body">
    <Modal :title="'Login'" :size="'modal-sm'" @modal-created="modalCreated">

      <template #default>
        <form class="p-2" @submit.prevent="login">
          <div class="mb-3">
            <label for="loginFormUsername" class="form-label">Username</label>
            <input type="text" v-model="loginForm.username" class="form-control" name="username" id="loginFormUsername" placeholder="Username" autocomplete="username">
          </div>
          <div class="mb-3">
            <label for="loginFormCurrentPassword" class="form-label">Password</label>
            <input type="password" v-model="loginForm.password" class="form-control" name="current-password" id="loginFormCurrentPassword" placeholder="Password" autocomplete="current-password">
          </div>
          <div class="progress" role="progressbar" aria-label="Form is loading" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height: 5px"  v-show="loadingState">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success w-100"></div>
          </div>
          <button type="submit" class="btn btn-primary " v-show="false" />
        </form>
      </template>

      <template #footer>
        <button type="button" class="btn btn-link" @click.prevent="register">Register</button>
        <div class="vr"></div>
        <button type="submit" class="btn btn-primary" @click.prevent="login">
          <span role="status">Sign in</span>
        </button>
      </template>
    </Modal>
  </teleport>

</template>